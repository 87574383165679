import React, { Fragment } from "react";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import instance from "./translations/i18n";
import AucaPage from "./components/AucaPage";
import SnackbarContainer from "./components/SnackbarContainer";
import LoginPage from "./components/LoginPage";
import SignUpPage from "./components/SignUpPage";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import DemoPage from "./components/DemoPage";
import DemoPageFeria from "./components/DemoPageFeria";
import SurveyPage from "./components/SurveyPage";
import ErrorPage from "./components/ErrorPage";
import AdminPage from "./components/AdminPage";
import ResultPage from "./components/ResultPage";
import ChangePasswordPage from "./components/ChangePasswordPage";
import AdminProfilePage from "./components/AdminProfilePage";
import RecoveryAccountPage from "./components/RecoveryAccountPage";
import { withLayout } from "./components/Layout";
import TermsPage from "components/TermsPage";
import { clearSnackbarAction } from "store/actions/snackbarActions";
import { store } from "./store";
import CompanyStructurePage from "./components/CompanyStructurePage";

import "./App.css";

const App = () => {
  const persistor = persistStore(store);
  const onBeforeLift = () => {
    // Remove snackbar messages on store load
    store.dispatch(clearSnackbarAction());
  };

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate
          onBeforeLift={onBeforeLift}
          loading={null}
          persistor={persistor}
        >
          <Router>
            <Fragment>
              <I18nextProvider i18n={instance}>
                <Routes>
                  <Route
                    path="/"
                    element={withLayout(<AucaPage />, undefined, "home")}
                  />
                  <Route path="/login" element={withLayout(<LoginPage />)} />
                  <Route path="/signup" element={withLayout(<SignUpPage />)} />
                  <Route
                    path="/password-change"
                    element={withLayout(<RecoveryAccountPage />)}
                  />
                  <Route
                    path="/password"
                    element={withLayout(
                      <ChangePasswordPage />,
                      "passwordManagement"
                    )}
                  />
                  <Route
                    path="/admin-profile"
                    element={withLayout(
                      <AdminProfilePage />,
                      "profileManagement"
                    )}
                  />
                  <Route
                    path="/survey"
                    element={withLayout(<SurveyPage />, "survey")}
                  />
                  <Route path="*" element={<ErrorPage type="error" />} />
                  <Route
                    path="/unauthorized"
                    element={<ErrorPage type="unauthorized" />}
                  />
                  <Route
                    path="/survey/result"
                    element={withLayout(<ResultPage />, "results")}
                  />
                  <Route
                    path="/plan"
                    element={withLayout(<DemoPage />, "demo", "plan")}
                  />
                  <Route
                    path="/feria"
                    element={withLayout(<DemoPageFeria />, "demo", "plan")}
                  />
                  <Route
                    path="/admin"
                    element={withLayout(<AdminPage />, "userManagement")}
                  />
                  <Route
                    path="/terms"
                    element={withLayout(<TermsPage />)}
                  />
                   <Route
                    path="/companyStructure"
                    element={withLayout(<CompanyStructurePage />, undefined, "structure")}
                  />
                </Routes>
              </I18nextProvider>
            </Fragment>
          </Router>
          <SnackbarContainer />
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
