import React, { useState } from "react";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff, CancelRounded } from "@material-ui/icons";

import styles from "./index.module.scss";
import PungoButton from "pungo-ui/PungoButton";

interface ISelectableOptions {
  value: number | string;
  label: number | string;
}
interface PungoInputProps {
  name: string;
  helperText?: string | undefined;
  type?: "password" | "number" | "text" | "select" | "date";
  onChange: (value: any) => void;
  value: string | number;
  options?: ISelectableOptions[];
  disabled?: boolean;
  size?: "small" | "medium";
  canBeErased?: boolean;
}

const PungoInput: React.FC<PungoInputProps> = (props) => {
  const {
    name,
    helperText,
    type = "text",
    size = "medium",
    onChange,
    value,
    options,
    disabled,
    canBeErased = false,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const isDateInput = type === "date";

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  return (
    <div className={styles.container}>
      {type !== "select" ? (
        <TextField
          label={name}
          className={styles.formControl}
          value={value}
          disabled={disabled}
          size={size}
          inputProps={
            isDateInput
              ? {
                  min: new Date().toLocaleDateString("fr-CA"),
                  shrink: true,
                }
              : undefined
          }
          InputLabelProps={
            isDateInput
              ? {
                  shrink: true,
                }
              : undefined
          }
          onKeyDown={isDateInput ? (e) => e.preventDefault() : undefined}
          type={
            type === "password" ? (showPassword ? "text" : "password") : type
          }
          variant="outlined"
          error={!!helperText}
          helperText={helperText}
          onChange={(event) => onChange(event.target.value)}
          InputProps={{
            endAdornment:
              type === "password" ? (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ) : type === "text" && value && canBeErased ? (
                <PungoButton
                  onClick={() => onChange("")}
                  type="icon"
                  icon={<CancelRounded fontSize="small" />}
                />
              ) : undefined,
          }}
        />
      ) : (
        <FormControl disabled={disabled} className={styles.formControl}>
          <InputLabel>{name}</InputLabel>
          <Select
            label={name}
            className={styles.formControl}
            size={size}
            value={String(value)}
            onChange={(event: SelectChangeEvent) => {
              onChange(event.target.value as string);
            }}
          >
            {options?.map(({ value, label }) => (
              <MenuItem value={value} key={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </div>
  );
};

export default PungoInput;
