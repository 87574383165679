import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

import { isEmail } from "../utils/validations";
import PungoInput from "../../pungo-ui/PungoInput";
import PungoButton from "../../pungo-ui/PungoButton";
import PungoModal from "../../pungo-ui/PungoModal";
import PungoNotificationModal from "../../pungo-ui/PungoNotificationModal";

import styles from "./index.module.scss";
import { forgotPassword, verifyAccount } from "store/actions/accountActions";
import { logInSession } from "store/actions/authActions";
import { useAppDispatch } from "app/hooks";
import { useSelector } from "react-redux";
import { selectAccountVerifyingLoader } from "store/slices/account";
import CommonTemplate from "components/common/CommonTemplate";

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { search } = location;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [recoveryPassword, setRecoveryPassword] = useState("");
  const [showInputModal, setShowInputModal] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const isVeryfingAccount = useSelector(selectAccountVerifyingLoader);

  const isRecoveryEmailValid = isEmail(recoveryPassword);
  const urlParams = new URLSearchParams(search);
  const verifyEmail = urlParams?.get("username") || "";
  const code = urlParams?.get("code") || "";

  const onCloseModal = () => {
    setRecoveryPassword("");
    setShowInputModal(false);
  };

  let didInit = false;

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!didInit) {
      didInit = true;
      if (code && verifyEmail) {
        dispatch(verifyAccount({ username: verifyEmail, code }));
      }
    }
  }, []);

  const handleOnConfirmModal = () => {
    dispatch(forgotPassword({ username: recoveryPassword }));
    onCloseModal();
  };

  const handleOnLogin = () => {
    dispatch(logInSession({ username: email, password }, () => navigate("/")));
    setPassword("");
    setEmail("");
  };

  useEffect(() => {
    if (email.length) {
      setTimeout(() => {
        if (isEmail(email)) {
          setIsValidEmail(true);
        } else {
          setIsValidEmail(false);
        }
      }, 300);
    }
  }, [email]);

  return (
    <div>
      <CommonTemplate title={`${t("accountPage.startSession")}`} height={400}>
        <PungoInput
          name={`${t("accountPage.emailLabel")}`}
          value={email}
          onChange={setEmail}
          helperText={
            isValidEmail ? undefined : `${t("accountPage.invalidEmail")}`
          }
        />
        <PungoInput
          name={`${t("accountPage.passwordLabel")}`}
          type="password"
          value={password}
          onChange={setPassword}
        />
        <PungoButton
          label={`${t("accountPage.loginLabel")}`}
          onClick={handleOnLogin}
          color="secondary"
          classNames={styles.sendButton}
          disabled={!isValidEmail || !password || !email.length}
        />
        <div>
          <PungoButton
            label={`${t("accountPage.forgetAccountLabel")}`}
            type="text"
            onClick={() => setShowInputModal(true)}
            color="secondary"
          />
          <div>
            <span>{`${t("accountPage.haveNoAccountLabel")}`}</span>
            <PungoButton
              label={`${t("accountPage.signUpLabel")}`}
              type="text"
              onClick={() => navigate("/signup")}
              color="secondary"
            />
          </div>
        </div>
      </CommonTemplate>

      <PungoModal
        open={showInputModal}
        handleClose={setShowInputModal}
        primaryAction={{
          label: `${t("accountPage.signUp")}`,
          onClick: handleOnConfirmModal,
          disabled: !isRecoveryEmailValid,
        }}
        secondaryAction={{
          label: `${t("accountPage.cancelLabel")}`,
          onClick: onCloseModal,
        }}
        smallModal={true}
      >
        <div className={styles.recoveryText}>
          {t("accountPage.entryMailLabel")}
        </div>
        <div className={styles.recoveryInput}>
          <PungoInput
            onChange={setRecoveryPassword}
            value={recoveryPassword}
            name={`${t("accountPage.emailLabel")}`}
          />
        </div>
      </PungoModal>
      <PungoNotificationModal open={isVeryfingAccount}>
        <div className={styles.verifyingAccount}>
          {t("verifyingAccountLabel")}
        </div>
      </PungoNotificationModal>
    </div>
  );
};

export default LoginPage;
