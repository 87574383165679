import { useState } from "react";

import styles from "./index.module.scss";

interface node {
  id: String;
  name: String;
  template: String;
  children?: node[];
}

const DATAINI: node = {
  id: "root",
  name: "Pungo Tech",
  template: "plantilla General",
  children: [
    {
      id: "1",
      name: "Industria",
      template: "plantilla Industria",
      children: [
        {
          id: "2",
          name: "Manufactura",
          template: "plantilla Manufactura",
        },
        {
          id: "3",
          name: "Agricola",
          template: "plantilla Agricola",
          children: [
            {
              id: "4",
              name: "Cacao",
              template: "plantilla Agricola",
            },
            {
              id: "5",
              name: "Rosas",
              template: "plantilla Agricola",
            },
          ],
        },
      ],
    },
    {
      id: "6",
      name: "Servicios",
      template: "plantilla Servicios",
      children: [
        {
          id: "7",
          name: "Banca",
          template: "plantilla Banca",
        },
      ],
    },
    {
      id: "8",
      name: "Consultoría",
      template: "plantilla General",
    },
  ],
};

function Tree({
  data,
  deleteNode,
  dataFull,
  increaseNodeCount,
  updateAuxNode,
  updateAuxTemplate,
}: {
  data: node;
  deleteNode: any;
  dataFull: node;
  increaseNodeCount: any;
  updateAuxNode: any;
  updateAuxTemplate: any;
}) {
  let auxEditNode: String = "";
  let auxEditTemplate: String = "";

  function sNode(data: node, nodeToFind: string) {
    let vFind: String[] = [];

    function ssNode(data: node, nodeToFind: string) {
      if (data.name.toUpperCase() === nodeToFind.toUpperCase()) {
        vFind.push(data.id);
        vFind.push(data.name);
        vFind.push(data.template);
      } else {
        if (Array.isArray(data.children)) {
          data.children.map((data) => {
            ssNode(data, nodeToFind);
          });
        }
      }
    }

    ssNode(data, nodeToFind);

    return vFind;
  }

  function openDataInput(e: any) {
    (document.getElementById("rootNode") as HTMLInputElement).innerText = e.target.id;
    let element = document.getElementById("modalAddNode") as HTMLInputElement;
    if (element != null) {
      element.style.display = "block";
    }
  }

  function editDataInput(e: any) {
    let element = document.getElementById("modalEditNode") as HTMLInputElement;
    let w = sNode(dataFull, e.target.id);
    (document.getElementById("editNode") as HTMLInputElement).innerText = w[1].toString();
    (document.getElementById("editTemplate") as HTMLInputElement).innerText = w[2].toString();
    updateAuxNode(w[1].toString());
    updateAuxTemplate(w[2].toString());

    if (element != null) {
      element.style.display = "block";
    }
  }

  function ReqAddNode({ name }: { name: string }) {
    return (
      <button
        id={name}
        className={styles.btnTreeAdd}
        onClick={(e) => {
          openDataInput(e);
        }}
      >
        Agregar
      </button>
    );
  }

  function ReqEditNode({ name }: { name: string; dataOrg: node }) {
    return (
      <button
        id={name}
        className={styles.btnTreeEdit}
        onClick={(e) => {
          editDataInput(e);
        }}
      >
        Editar
      </button>
    );
  }

  function ReqDeleteNode({ name, deleteNode, data }: { name: string; deleteNode: any; data: node }) {
    return (
      <button
        id={name}
        className={styles.btnTreeDelete}
        onClick={(e) => {
          deleteNode(data, e);
        }}
      >
        Borrar
      </button>
    );
  }

  increaseNodeCount();

  if (Array.isArray(data.children)) {
    return (
      <div id="structureTree">
        {data.id === "root" ? (
          <ul className={styles.tree}>
            <div className={styles.treeItem}>
              <div className={styles.treeItemTxt}>
                {data.name} <span className={styles.template}>‣‣‣ {data.template} </span>
              </div>
              <div className={styles.treeItemBtn}>
                <ReqAddNode name={String(data.name)} />
              </div>
            </div>

            {!!data.children?.length &&
              data.children.map((data) => {
                return (
                  <div key={"d1" + data.id} style={{ marginLeft: 30 }}>
                    <Tree data={data} deleteNode={deleteNode} dataFull={dataFull} increaseNodeCount={increaseNodeCount} updateAuxNode={updateAuxNode} updateAuxTemplate={updateAuxTemplate} />
                  </div>
                );
              })}
          </ul>
        ) : (
          <li key={"li1" + data.id}>
            <details>
              <summary>
                <div className={styles.treeItem}>
                  <div className={styles.treeItemTxt}>
                    {data.name} <span className={styles.template}> ‣‣‣ {data.template} </span>
                  </div>
                  <div className={styles.treeItemBtn}>
                    <ReqAddNode name={String(data.name)} />
                    <ReqEditNode name={String(data.name)} dataOrg={dataFull} />
                    <ReqDeleteNode name={String(data.name)} deleteNode={deleteNode} data={dataFull} />
                  </div>
                </div>
              </summary>
              {!!data.children?.length &&
                data.children.map((data) => {
                  return (
                    <div key={"d2" + data.id} style={{ marginLeft: 30 }}>
                      <Tree data={data} deleteNode={deleteNode} dataFull={dataFull} increaseNodeCount={increaseNodeCount} updateAuxNode={updateAuxNode} updateAuxTemplate={updateAuxTemplate} />
                    </div>
                  );
                })}
            </details>
          </li>
        )}
      </div>
    );
  } else {
    return (
      <li>
        <div className={styles.treeItem}>
          <div className={styles.treeItemTxt}>
            {data.name} <span className={styles.template}> ‣‣‣ {data.template} </span>
          </div>

          <div className={styles.treeItemBtn}>
            <ReqAddNode name={String(data.name)} />
            <ReqEditNode name={String(data.name)} dataOrg={dataFull} />
            <ReqDeleteNode name={String(data.name)} deleteNode={deleteNode} data={dataFull} />
          </div>
        </div>
      </li>
    );
  }
}

function InputData({
  data,
  searchElements,
  updateState,
  addChild,
  updateNode,
  updateDataJSON,
  auxNode,
  auxTemplate,
}: {
  data: node;
  searchElements: any;
  updateState: any;
  addChild: any;
  updateNode: any;
  updateDataJSON: any;
  auxNode: any;
  auxTemplate: any;
}) {
  let rootNode = "";
  let newNode = "";
  let template = "";
  let searchStatus = false;

  function closeErrorModal() {
    let element = document.getElementById("modalError") as HTMLInputElement;
    if (element != null) {
      element.style.display = "none";
    }
  }

  function openErrorModal() {
    let element = document.getElementById("modalError") as HTMLInputElement;
    if (element != null) {
      element.style.display = "block";
    }
  }

  function openErrorModalEdit() {
    let element = document.getElementById("modalErrorEdit") as HTMLInputElement;
    if (element != null) {
      element.style.display = "block";
    }
  }

  function closeErrorModalEdit() {
    let element = document.getElementById("modalErrorEdit") as HTMLInputElement;
    if (element != null) {
      element.style.display = "block";
    }
  }

  function closeAddNodeModal(event: any) {
    let id = event.target.id;
    if (id === "modalAddNode" || id === "modalAddNodeContent" || id === "modalAddNodeCloseBtn" || id === "dataLeaf2") {
      let element = document.getElementById("modalAddNode") as HTMLInputElement;
      if (element != null) {
        element.style.display = "none";
      }
    }
  }

  function closeEditNodeModal(event: any) {
    let id = event.target.id;
    if (id === "modalEditNode" || id === "modalEditNodeContent" || id === "modalEditNodeCloseBtn" || id === "dataLeaf4") {
      let element = document.getElementById("modalEditNode") as HTMLInputElement;
      if (element != null) {
        element.style.display = "none";
      }
    }
  }

  function ModalError() {
    return (
      <div
        id="modalError"
        className={styles.modalError}
        onClick={() => {
          closeErrorModal();
        }}
      >
        <div id="modalErrorContent" className={styles.modalErrorContent}>
          <div
            className={styles.modalErrorClose}
            onClick={() => {
              closeErrorModal();
            }}
          >
            {" "}
            X{" "}
          </div>
          <p>ERROR</p>
          <p>Revise los datos ingresados.</p>
          <ol>
            <li>El nombre del nodo raíz debe coincidir con un nodo existente en la estructura actual.</li>
            <li>El nombre del nuevo nodo no debe existir en la estructura actual.</li>
            <li>El nombre de la plantilla es opcional. Si no ingresa un nombre se registrará el mismo nombre de la plantilla del nodo.</li>
          </ol>
        </div>
      </div>
    );
  }

  function ModalErrorEdit() {
    return (
      <div
        id="modalErrorEdit"
        className={styles.modalError}
        onClick={() => {
          closeErrorModalEdit();
        }}
      >
        <div id="modalErrorContent" className={styles.modalErrorContent}>
          <div
            className={styles.modalErrorClose}
            onClick={() => {
              closeErrorModalEdit();
            }}
          >
            {" "}
            X{" "}
          </div>
          <p>ERROR</p>
          <p>Revise los datos ingresados.</p>
          <ol>
            <li>El nombre del nodo no debe existir en la estructura actual.</li>
          </ol>
        </div>
      </div>
    );
  }

  function sanitizeInput(txtIn: string, maxLength: number): string {
    let txtOut = txtIn.substring(0, maxLength);
    let p = document.createElement("p");
    p.textContent = txtOut;
    txtOut = p.innerHTML;
    txtOut = txtOut.replaceAll(/[\n]/gm, " ");
    txtOut = txtOut.trim();
    return txtOut;
  }

  return (
    <div>
      <ModalError />

      <div
        id="modalAddNode"
        className={styles.modalAddNode}
        onClick={(event) => {
          closeAddNodeModal(event);
        }}
      >
        <div id="modalAddNodeContent" className={styles.modalAddNodeContent}>
          <div className={styles.dataLeaf}>
            <div
              id="modalAddNodeCloseBtn"
              className={styles.modalAddNodeClose}
              onClick={(event) => {
                closeAddNodeModal(event);
              }}
            >
              {" "}
              X{" "}
            </div>
            <div className={styles.dataTitle}>
              Agregar un nuevo elemento
              <div className={styles.dataTitleAux}></div>
            </div>
            <div className={styles.dataSection}>
              <label htmlFor="rootNode" className={styles.labelTag}>
                {" "}
                Nombre del punto donde se añadirá el registro
                <div id="rootNode" className={styles.labelIn} contentEditable="false" suppressContentEditableWarning={true}>
                  {" "}
                </div>
              </label>
              <div className={styles.tooltip}>Si el nombre del elemento no corresponde con el del punto donde desea realizar la inserción, cierre esta pantalla y vuelva a seleccionar otro nodo</div>
            </div>
            <div className={styles.dataSection}>
              <label htmlFor="newNode" className={styles.labelTag}>
                {" "}
                Nombre del nuevo elemento
                <div id="newNode" className={styles.labelIn} contentEditable="true" suppressContentEditableWarning={true}>
                  {" "}
                </div>
              </label>
              <div className={styles.tooltip}>Este nombre debe ser diferente a todos los elementos actuales</div>
            </div>

            <div className={styles.dataSection}>
              <label htmlFor="template" className={styles.labelTag}>
                {" "}
                Plantillla del nuevo elemento
                <div id="newTemplate" className={styles.labelIn} contentEditable="true" suppressContentEditableWarning={true}>
                  {" "}
                </div>
              </label>
              <div className={styles.tooltip}>Si no ingresa un nombre de plantilla se asignará la misma plantilla del nodo padre</div>
            </div>
          </div>

          <div id="dataLeaf2" className={styles.dataLeaf2}>
            <div className={styles.buttonPosition}>
              <button
                className={styles.btnAddNode}
                onClick={() => {
                  rootNode = (document.getElementById("rootNode") as HTMLInputElement).innerText;
                  newNode = (document.getElementById("newNode") as HTMLInputElement).innerText;

                  rootNode = sanitizeInput(rootNode, 100);
                  newNode = sanitizeInput(newNode, 100);
                  if (rootNode === "" || newNode === "") {
                    openErrorModal();
                  } else {
                    searchStatus = searchElements(data, rootNode, newNode);
                  }
                  if (searchStatus) {
                    template = (document.getElementById("newTemplate") as HTMLInputElement).innerText;
                    let newData = addChild(data, rootNode, newNode, template);
                    updateDataJSON(newData);
                    updateState();

                    let element = document.getElementById("modalAddNode") as HTMLInputElement;
                    if (element != null) {
                      element.style.display = "none";
                    }
                  } else {
                    openErrorModal();
                  }
                }}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        id="modalEditNode"
        className={styles.modalAddNode}
        onClick={(event) => {
          closeEditNodeModal(event);
        }}
      >
        <div id="modalEditNodeContent" className={styles.modalAddNodeContent}>
          <div className={styles.dataLeaf}>
            <div
              id="modalEditNodeCloseBtn"
              className={styles.modalAddNodeClose}
              onClick={(event) => {
                closeEditNodeModal(event);
              }}
            >
              {" "}
              X{" "}
            </div>
            <div className={styles.dataTitle}>
              Editar elemento
              <div className={styles.dataTitleAux}></div>
            </div>

            <div className={styles.dataSection}>
              <label htmlFor="editNode" className={styles.labelTag}>
                {" "}
                Editar nodo
                <div id="editNode" className={styles.labelIn} contentEditable="true" suppressContentEditableWarning={true}>
                  {" "}
                </div>
              </label>
              <div className={styles.tooltip}>Este nombre debe ser diferente a todos los elementos actuales</div>
            </div>

            <div className={styles.dataSection}>
              <label htmlFor="editTemplate" className={styles.labelTag}>
                {" "}
                Editar nombre de la plantilla
                <div id="editTemplate" className={styles.labelIn} contentEditable="true" suppressContentEditableWarning={true}>
                  {" "}
                </div>
              </label>
              <div className={styles.tooltip}>Editar</div>
            </div>
          </div>

          <div id="dataLeaf4" className={styles.dataLeaf2}>
            <div className={styles.buttonPosition}>
              <button
                className={styles.btnAddNode}
                onClick={() => {
                  let editedNode = (document.getElementById("editNode") as HTMLInputElement).innerText;
                  let editedTemplate = (document.getElementById("editTemplate") as HTMLInputElement).innerText;

                  searchStatus = auxNode === editedNode || searchElements(data, auxNode, editedNode);

                  if (searchStatus) {
                    editedNode = sanitizeInput(editedNode, 100);
                    editedTemplate = sanitizeInput(editedTemplate, 100);
                    if (editedNode === "") {
                      openErrorModalEdit();
                    }

                    let newData = updateNode(data, auxNode, editedNode, editedTemplate);
                    updateDataJSON(newData);
                    updateState();

                    let element = document.getElementById("modalEditNode") as HTMLInputElement;
                    if (element != null) {
                      element.style.display = "none";
                    }
                  } else {
                    openErrorModal();
                  }
                }}
              >
                Registrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default function CompanyStructure() {
  const [dataJSON, setDataJSON] = useState(DATAINI);
  const [newRender, setNewRender] = useState(true);
  const [auxNode, setAuxNode] = useState("");
  const [auxTemplate, setAuxTemplate] = useState("");

  let nodeCount = 0;

  function updateDataJSON(data: node) {
    setDataJSON(data);
  }

  function updateState(): boolean {
    setNewRender(!newRender);
    return false;
  }

  function updateAuxNode(data: String) {
    setAuxNode(data.toString());
  }

  function updateAuxTemplate(data: String) {
    setAuxTemplate(data.toString());
  }

  function increaseNodeCount() {
    nodeCount += 1;
  }

  function pathToNode(data: node, node: string) {
    let path: number[] = [];
    let ffind = false;
    function parcialPath(data: node, node: string, key: number) {
      path.push(key);
      if (node === data.name) {
        ffind = true;
      } else {
        if (Array.isArray(data.children)) {
          data.children.map((data, key) => {
            if (!ffind) {
              parcialPath(data, node, key);
              if (!ffind) {
                path.pop();
              }
            }
            return false;
          });
        }
      }
    }
    parcialPath(data, node, 0);

    let dString = "";
    for (let i = 1; i < path.length; i++) {
      dString += ".children[" + path[i] + "]";
    }

    return dString;
  }

  function deleteNode(data: node, e: any): boolean {
    if (!window.confirm("Confirma que desea eliminar el nodo: " + e.target.id)) {
      return false;
    } else {
      let path = pathToNode(data, e.target.id);
      let firstP = path.lastIndexOf("[");
      let lastP = path.lastIndexOf("]");
      let pathRoot = path.substring(0, firstP);
      let pathE = path.substring(firstP + 1, lastP);
      let dataCP: node = data;
      let sToSplice = `dataCP${pathRoot}.splice(${pathE},1)`;

      // eslint-disable-next-line
      eval(sToSplice);
      trimTreeEmptyChild(dataCP);
      setDataJSON(dataCP);
      updateState();
      return true;
    }
  }

  function trimTreeEmptyChild(data: node) {
    if (Array.isArray(data.children)) {
      if (data.children.length === 0) {
        delete data.children;
      } else {
        data.children.map((data) => {
          trimTreeEmptyChild(data);
          return true;
        });
      }
    }
    return true;
  }

  function searchElements(data: node, rootNode: string, newNode: string): boolean {
    rootNode = rootNode.trim();
    newNode = newNode.trim();

    let searchStatus = false;

    function searchNodeName(data: node, node: string) {
      let nodeLocated = false;

      function searchE(data: node, nodeName: string) {
        if (data.name.toUpperCase().toString() == nodeName.toUpperCase().toString()) {
          nodeLocated = true;
        } else {
          if (Array.isArray(data.children)) {
            data.children.map((data) => {
              searchE(data, nodeName);
              return true;
            });
          }
        }
      }
      searchE(data, node);
      return nodeLocated;
    }

    let locateRootNode = searchNodeName(data, rootNode);
    let locateNewNode = searchNodeName(data, newNode);


    if (!locateRootNode || locateNewNode) {
      searchStatus = false;
    } else {
      searchStatus = true;
    }

    return searchStatus;
  }

  function addChild(data: node, rootNode: string, newNode: string, template: string) {
    increaseNodeCount();

    function findPosition(data: node, rootNode: string, template: string) {
      let auxTemplate = template;

      function insertE(data: node, rootNode: string, template: string) {
        if (template === "") {
          auxTemplate = String(data.template);
        }

        if (data.name.toUpperCase() === rootNode.toUpperCase()) {
          if (Array.isArray(data.children)) {
            data.children.push({
              id: String(nodeCount),
              name: newNode,
              template: auxTemplate,
            });
          } else {
            let auxE = [];
            auxE.push({
              id: String(nodeCount),
              name: newNode,
              template: auxTemplate,
            });
            data.children = auxE;
          }
        } else {
          if (Array.isArray(data.children)) {
            data.children.map((data) => {
              insertE(data, rootNode, template);
              return true;
            });
          }
        }
      }
      insertE(data, rootNode, template);
    }
    findPosition(data, rootNode, template);
    return data;
  }

  function updateNode(data: node, nodeToFind: string, newNode: string, template: string) {
    function ssNode(data: node, nodeToFind: string, newNode: string, template: string) {
      if (data.name.toUpperCase() === nodeToFind.toUpperCase()) {
        data.name = newNode;
        data.template = template;
      } else {
        if (Array.isArray(data.children)) {
          data.children.map((data) => {
            ssNode(data, nodeToFind, newNode, template);
          });
        }
      }
    }

    ssNode(data, nodeToFind, newNode, template);

    return data;
  }

  return (
    <div className={styles.containerCompanyStructure}>
      Registro de la estructura de la organización
      <div className={styles.flexContainer}>
        <div className={styles.tree}>
          <Tree data={dataJSON} deleteNode={deleteNode} dataFull={dataJSON} increaseNodeCount={increaseNodeCount} updateAuxNode={updateAuxNode} updateAuxTemplate={updateAuxTemplate} />

          <div className={styles.addNode}>
            <InputData
              data={dataJSON}
              searchElements={searchElements}
              updateState={updateState}
              addChild={addChild}
              updateNode={updateNode}
              updateDataJSON={updateDataJSON}
              auxNode={auxNode}
              auxTemplate={auxTemplate}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
