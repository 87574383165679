import React from "react";

import styles from "./index.module.scss";

interface PungoSectionCardProps {
  title: string;
  description?: string;
  children: React.ReactNode;
  id?: string;
}

const PungoSectionCard: React.FC<PungoSectionCardProps> = (props) => {
  const { title, description, children, id } = props;
  return (
    <section className={styles.container} id={id}>
      <div className={styles.header}>{title}</div>
      {description && <div className={styles.description}>{description}</div>}
      <div className={styles.children}>{children}</div>
    </section>
  );
};

export default PungoSectionCard;
