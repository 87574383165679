import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { changeLanguage } from "i18next";
import TeamSection from "../AucaPage/sections/TeamSection";
import ContactUsSection from "../AucaPage/sections/ContactUsSection";
import CompanyStructure from "./sections/CompanyStructure";
import PartnersSection from "../AucaPage/sections/PartnersSection";
import AnimationSection from "../AucaPage/sections/AnimationSection";
import PungoButton from "../../pungo-ui/PungoButton";
import PlanSelectorSection from "../DemoPage/sections/DemoPlanSelectorSection";
import InstructionsSection from "../DemoPage/sections/InstructionsSection";


import styles from "./index.module.scss";

const CompanyStructurePage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isSpanishLanguageSelected = i18n.language === "es";


  return (
    <>
      <section className={styles.container}>
        <AnimationSection />
        <PlanSelectorSection />
        <InstructionsSection />
      {/* </section> */}
      {/* <div className={styles.mainContainer}> */}

        <CompanyStructure />
        <TeamSection />
        <PartnersSection />
        <ContactUsSection />
        <div className={styles.languageSection}>
          <PungoButton
            label={`${t("language.englishLabel")}`}
            type="text"
            onClick={() => changeLanguage("en")}
            color="secondary"
            disabled={!isSpanishLanguageSelected}
          />
          <PungoButton
            label={`${t("language.spanishLabel")}`}
            type="text"
            onClick={() => changeLanguage("es")}
            color="secondary"
            disabled={isSpanishLanguageSelected}
          />
        </div>
        <PungoButton
          label={`${t("politicsAndReferenceTerms")}`}
          type="text"
          onClick={() => navigate("/terms")}
          color="secondary"
          classNames={styles.politics}
        />
        <div className={styles.copyright}>Copyright © 2023 Pungo</div>
      {/* </div> */}
      </section>
    </>
  );
};

export default CompanyStructurePage;
