import React from "react";

import colors from "../../styles/export.module.scss";
import styles from "./index.module.scss";

interface PungoOpinionCardProps {
  color?: string;
  author: string;
  opinion: string;
}

const PungoOpinionCard: React.FC<PungoOpinionCardProps> = (props) => {
  const { color = colors.green4, author, opinion } = props;

  return (
    <div className={styles.body}>
      <div className={styles.container} style={{ background: color }}>
        <div>{opinion}</div>
        <div>{`- ${author}`}</div>
      </div>
      <div className={styles.triangle} style={{ borderTopColor: color }} />
    </div>
  );
};

export default PungoOpinionCard;
